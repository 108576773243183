<template>
  <v-btn @click="$router.go(-1)" rounded class="white--text px-2 clav-linear-background">
    <unicon name="arrow-back-icon" width="20" height="20" viewBox="0 0 20.71 37.261" fill="#ffffff" />
    <p class="ml-2">Voltar</p>
  </v-btn>
</template>
<script>
export default {
  name: "Voltar",
};
</script>
<style scoped>
</style>
